<template>
  <Header>
    Tanks
    <template #right>
      <BadgeBtn @click="openTank()">
        + Tank
      </BadgeBtn>
    </template>
  </Header>
  <Table>
    <template v-slot:headRow>
      <TH>Edit</TH>
      <TH>Name</TH>
      <TH>Short Name</TH>
      <TH>Size</TH>
      <TH>Unit</TH>
    </template>
    <template v-slot:body>
      <TR v-for="(item, index) in tanks" :key="index">
        <TD>
          <ButtonGroup>
            <Button @click="openTank(item.id)">
              <BIconPencilSquare />
            </Button>
            <Button @click="openDeleteTank(item.id)">
              <BIconTrash />
            </Button>
          </ButtonGroup>
        </TD>
        <TD>{{ item.name }}</TD>
        <TD>{{ item.short }}</TD>
        <TD>{{ item.size }}</TD>
        <TD>{{ unit[item.unit] }}</TD>
      </TR>
    </template>
  </Table>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { UNIT } from "@/services/enums"
// import { getUnitById } from "@/services/helper";

import comp from "@/components/base"
import icons from "../../assets/icons"

export default {
  name: "TankTable",
  setup() {
    // return getUnitById;
  },
  data() {
    return {
      unit: UNIT.TEXT,
    }
  },
  components: {
    ...comp,
    ...icons,
  },
  methods: {
    ...mapActions(["openDeleteTank", "openTank"]),
  },
  computed: {
    ...mapGetters(["tanks"]),
  },
}
</script>
