<template>
  <Container>
    <TankTable />
    <BreweryTable />
  </Container>
</template>

<script>
import TankTable from "../components/tabels/TankTable.vue"
import BreweryTable from "../components/tabels/BreweryTable.vue"
import comp from "../components/base"

export default {
  name: "Tanks",
  components: {
    ...comp,
    TankTable,
    BreweryTable,
  },
}
</script>
