<template>
  <Header>
    Brewery
    <template #right>
      <BadgeBtn @click="openBrewery()">
        + Brewery
      </BadgeBtn>
    </template>
  </Header>
  <BTable :header="[, 'Name', 'Size', 'Efficiency', 'Evaporation']">
    <tr v-for="(item, index) in breweries" :key="index">
      <td>
        <ButtonGroup>
          <Button @click="openBrewery(item.id)">
            <BIconPencilSquare />
          </Button>
          <Button @click="openDeleteBrewery(item.id)">
            <BIconTrash />
          </Button>
        </ButtonGroup>
      </td>
      <td
        v-for="(callback, index) in [
          item => item.name,
          item => item.size + UNIT.TEXT[item.unit],
          item => item.efficiency + ' %',
          item => item.evaporation + ' l/h',
        ]"
        :key="index"
      >
        {{ callback(item) }}
      </td>
    </tr>
  </BTable>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { UNIT } from "@/services/enums"

import comp from "../../components/base"
import icons from "../../assets/icons"

export default {
  name: "TankTable",
  setup() {
    return {
      UNIT,
    }
  },
  components: {
    ...comp,
    ...icons,
  },
  methods: {
    ...mapActions(["openDeleteBrewery", "openBrewery"]),
  },
  computed: {
    ...mapGetters(["breweries"]),
  },
}
</script>
